<template>
  <MobileLearnRegularStatus
      v-if="statusToggle"
      v-model:toggle="statusToggle"
      :items="statuses"
  />
  <template v-else>
    <LxpMobileHeader :use-route-name="true" />
    <main class="kb-main" id="kb-myclass">
      <!-- main-content -->
      <div class="main-content main-component">
        <div class="main-header">
          <div class="header-snb">
            <nav class="snb" style="justify-content: right">
              <ul class="snb-list">
                <li class="snb-item">
                  <button class="is-active" @click="showStatus">판매자격연수 현황</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- calendar -->
        <!-- content-section:진행중 연수 -->
        <template v-if="isReady">
          <div v-for="(item, index) in renderItems" :class="index > 0 ? 'mt-5' : ''" :key="index">
            <MobileTrainRegularCourse
                :title="item.title"
                :items="item.items"
            />
          </div>
        </template>
      </div>
      <!-- //main-content -->
    </main>
  </template>
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileLearnRegularStatus from '@/components/learn/regular/mobile/MobileLearnRegularStatus';
import MobileTrainRegularCourse from '@/components/train/mobile/MobileTrainRegularCourse';
import {trainRegularSetup} from '@/assets/js/modules/train/train-regular-setup';

export default {
  name: 'MobileTrainRegular',
  components: {MobileTrainRegularCourse, MobileLearnRegularStatus, LxpMobileHeader},
  setup: trainRegularSetup
}
</script>
